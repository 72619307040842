import styled from "styled-components";

export const StyledWrapper = styled.div`
  background: var(--dark-blue-2);
  padding: 5em 0;
`;

export const StyledContent = styled.div`
  > h2 {
    text-transform: capitalize;
    color: var(--white);
    font-size: 2em;
  }

  > p {
    color: var(--white);
    margin: 1em 0;
  }
`;

export const StyledProjectsCarousel = styled.div`
  margin-top: 2em;
`;

export const StyledDescription = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgba(23, 33, 57, 0.8);
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(100%);
  transition: all 0.3s ease;

  h3 {
    text-transform: capitalize;
    color: var(--white);
    margin-bottom: 0.5em;
  }

  p {
    color: var(--white);
    font-size: 1em;
    font-weight: 300;
  }
`;

export const StyledProjectCard = styled.div`
  position: relative;
  height: 20em;
  overflow: hidden;

  &:hover ${StyledDescription} {
    transform: translateY(0);
  }
`;

export const StyledProjectCardImg = styled.img`
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const StyledNavBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
  gap: 1em;

  button {
    height: 1.3em;
    width: 1.3em;
    border-radius: 50%;
    background: var(--white);
    border: none;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    justify-content: center;

    svg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
