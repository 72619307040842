/* eslint-disable no-undef */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  StyledWrapper,
  StyledContent,
  StyledContentLeft,
  StyledContentRight,
  StyledImg,
  StyledLogo,
  StyledHeading,
} from "./styles";

import catImg from "../../assets/img/heavy-duty-machine.png";
import greyLogo from "../../assets/img/grey-logo.png";

const WhoWeAre = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <StyledWrapper id="about-us">
      <StyledContent>
        <StyledContentLeft>
          <StyledLogo>
            <img src={greyLogo} alt="logo" />
          </StyledLogo>
        </StyledContentLeft>
        <StyledContentRight>
          <StyledHeading>
            <h3>who we are</h3>
          </StyledHeading>
          <p>
            Bandel Engineering, located in Ogun State, Nigeria, a leading
            high-tech firm specializing in precision engineering services.
            Established in 2017, we focus on civil works, electrical &
            automation, and mechanical installations, aiming to address
            infrastructure challenges and housing gaps in Nigeria.
          </p>
        </StyledContentRight>
      </StyledContent>
      <StyledImg src={catImg} alt="heavy duty machine" />
    </StyledWrapper>
  );
};

export default WhoWeAre;
