import Container from "../shared/Container/Container";
// import { heroImgs } from "../../data/heroImgsData";
import {
  StyledWrapper,
  StyledHero,
  StyledHeroLeft,
  StyledHeroRight,
  StyledHeroLeftContent,
  StyledBlueprintImg,
  StyledHeroRightImg,
  // StyledImgGrid,
  // StyledImgGridItem,
} from "./styles";

import bluePrintImg from "../../assets/img/blueprint.png";
import heroGridImg from "../../assets/img/hero-imgs/hero-img-group.png";
import heroGridMobileImg from "../../assets/img/mobile-img.png";

const Hero = () => {
  return (
    <StyledWrapper id="hero-section">
      <Container>
        <StyledHero>
          <StyledHeroLeft>
            <StyledBlueprintImg src={bluePrintImg} alt="blueprint" />
            <StyledHeroLeftContent>
              <h1>precise engineering solutions</h1>
              <p>Civil, Electrical and Mechanical Services</p>
            </StyledHeroLeftContent>
          </StyledHeroLeft>
          <StyledHeroRight>
            <StyledHeroRightImg>
              <img
                src={heroGridImg}
                className="web-hero-img"
                alt="Bandel Engineering"
              />
              <img
                src={heroGridMobileImg}
                className="mobile-hero-img"
                alt="Bandel Engineering"
              />
            </StyledHeroRightImg>
            {/* <StyledImgGrid>
              {heroImgs.map((img) => (
                <StyledImgGridItem key={img}>
                  <img src={img} />
                </StyledImgGridItem>
              ))}
            </StyledImgGrid> */}
          </StyledHeroRight>
        </StyledHero>
      </Container>
    </StyledWrapper>
  );
};

export default Hero;
