import styled from "styled-components";

export const StyledWrapper = styled.div`
  width: 100%;
  background: var(--dark-blue-2);
  padding-top: 40%;

  @media screen and (min-width: 991px) {
    height: 100vh;
    padding-top: 3em;
  }
`;

export const StyledHero = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media screen and (min-width: 991px) {
    flex-direction: row;
    gap: 1em;
  }
`;

export const StyledHeroLeft = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 991px) {
    width: 50%;
  }
`;

export const StyledHeroLeftContent = styled.div`
  h1 {
    text-transform: capitalize;
    color: var(--white);
    font-size: 2.6em;
    font-weight: 600;
    line-height: 1;
  }

  p {
    color: var(--red-1);
    font-size: 1.2em;
    width: 100%;
    margin-top: 0.6em;
  }

  @media screen and (min-width: 991px) {
    h1 {
      font-size: 5em;
    }

    p {
      font-size: 1.5em;
      width: 80%;
    }
  }
`;

export const StyledBlueprintImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
`;

export const StyledHeroRight = styled.div`
  width: 100%;

  @media screen and (min-width: 991px) {
    width: 50%;
  }
`;

export const StyledHeroRightImg = styled.div`
  width: 100%;

  img {
    width: 100%;
    object-fit: contain;
  }

  .web-hero-img {
    display: none;
  }

  @media screen and (min-width: 991px) {
    .web-hero-img {
      display: block;
    }

    .mobile-hero-img {
      display: none;
    }
  }
`;

export const StyledImgGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  width: 100%;
`;

export const StyledImgGridItem = styled.div`
  height: 5em;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
