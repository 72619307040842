/* eslint-disable no-undef */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Container from "../shared/Container/Container";
import { serviceData } from "../../data/servicesData";
import {
  StyledWrapper,
  StyledServiceItems,
  StyledServiceItem,
  StyledContent,
} from "./styles";

const Services = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <StyledWrapper id="services">
      <Container width="70%">
        <StyledContent>
          <h2>services</h2>
          <p>
            We offers innovative solutions in civil works, electrical &
            automation, and mechanical installations. Our expert team ensures
            precision, reliability, and excellence in every project, exceeding
            client expectations and driving sustainable growth.
          </p>
          <StyledServiceItems>
            {serviceData.map((service) => (
              <StyledServiceItem key={service.title}>
                <img src={service.img} alt="bandel engineering" />
                <h3>{service.title}</h3>
              </StyledServiceItem>
            ))}
          </StyledServiceItems>
        </StyledContent>
      </Container>
    </StyledWrapper>
  );
};

export default Services;
