/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useRef } from "react";
import { Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";
import Container from "../shared/Container/Container";
import { projects } from "../../data/projectsData";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  StyledWrapper,
  StyledContent,
  StyledProjectsCarousel,
  StyledProjectCard,
  StyledProjectCardImg,
  StyledDescription,
  StyledNavBtns,
} from "./styles";

const OurProjects = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-redundant-type-constituents
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  return (
    <StyledWrapper>
      <Container width="70%">
        <StyledContent>
          <h2>our projects</h2>
          <p>
            We offers innovative solutions in civil works, electrical &
            automation, and mechanical installations. Our expert team ensures
            precision, reliability, and excellence in every project, exceeding
            client expectations and driving sustainable growth.
          </p>
          <StyledProjectsCarousel>
            <Swiper
              modules={[Autoplay, Navigation]}
              slidesPerView={1.2}
              spaceBetween={15}
              // centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1.2,
                  // spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2.5,
                  //spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3.5,
                  //spaceBetween: 50,
                },
              }}
              onBeforeInit={(swiper: any) => {
                if (
                  swiper.params.navigation &&
                  typeof swiper.params.navigation !== "boolean"
                ) {
                  const nav = swiper.params.navigation;
                  nav.prevEl = prevRef.current;
                  nav.nextEl = nextRef.current;
                }
              }}
            >
              {projects.map((project: any, index: number) => (
                <SwiperSlide key={index}>
                  <StyledProjectCard>
                    <StyledProjectCardImg src={project.img} alt="something" />
                    <StyledDescription>
                      <h3>{project.title}</h3>
                      <p>{project.description}</p>
                    </StyledDescription>
                  </StyledProjectCard>
                </SwiperSlide>
              ))}
            </Swiper>
          </StyledProjectsCarousel>
          <StyledNavBtns>
            <button ref={prevRef} className="custom-button-prev">
              <span>
                <IoArrowBack />
              </span>
            </button>
            <button ref={nextRef} className="custom-button-next">
              <span>
                <IoArrowForward />
              </span>
            </button>
          </StyledNavBtns>
        </StyledContent>
      </Container>
    </StyledWrapper>
  );
};

export default OurProjects;
