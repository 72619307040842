import img1 from "../assets/img/cu-logo.jpeg";
import img2 from "../assets/img/kay-metal-logo.jpeg";
import img3 from "../assets/img/sample-client.png";

interface ClientItem {
  img: string;
}

export const clientData: ClientItem[] = [
  { img: img1 },
  { img: img2 },
  { img: img2 },
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img1 },
  { img: img2 },
  { img: img1 },
  { img: img1 },
];
