import styled from "styled-components";

export const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--white);
  z-index: 99;
`;

export const StyledNavbar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  position: relative;

  @media screen and (min-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledNavbarLeft = styled.div``;

export const StyledLogo = styled.div``;

export const StyledNavbarRight = styled.div<{ $isOpen: boolean }>`
  @media screen and (max-width: 990.98px) {
    display: ${(props) => (props.$isOpen ? "block" : "none")};
  }

  @media screen and (min-width: 991px) {
    display: block;
  }
`;

export const StyledNavItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3em;
  align-items: center;
  list-style: none;

  @media screen and (min-width: 991px) {
    flex-direction: row;
    align-items: center;
    gap: 1.3em;
  }
`;

export const StyledNavItem = styled.div`
  a {
    text-decoration: none;
    text-transform: capitalize;
    color: var(--dark-blue-1);
    font-weight: 400;
  }
`;

export const StyledHamburger = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  font-size: 2em;

  @media screen and (min-width: 991px) {
    display: none;
  }
`;
