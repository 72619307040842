import styled from "styled-components";

import bgImg from "../../assets/consult-img.jpg";

export const StyledWrapper = styled.div`
  position: relative;
  background: var(--dark-blue-2);

  &::after {
    content: "";
    position: absolute;
    height: 15em;
    background: var(--white);
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const StyledSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  padding: 2em 0;

  gap: 1em;
  z-index: 1;

  @media screen and (min-width: 991px) {
    flex-direction: row;
    align-items: center;
    width: 70%;
    padding: 3em 0;
    padding-top: 10em;
  }
`;

export const StyledSectionRight = styled.div`
  width: 100%;

  @media screen and (min-width: 991px) {
    width: 50%;
  }
`;

export const StyledSectionLeft = styled.div`
  width: 100%;

  @media screen and (min-width: 991px) {
    width: 50%;
  }
`;

export const StyledFormSection = styled.div`
  background: var(--gray-3);
  padding: 2em;

  h2 {
    text-transform: capitalize;
    color: var(--dakr-blue-1);
    margin-bottom: 0.5em;
  }

  form {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;

    input,
    textarea {
      border: 0.1em solid var(--gray-1);
      padding: 1em;
      border-radius: 0;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--red-1);
      border: none;
      padding: 1em;
      text-transform: capitalize;
      color: var(--white);
      width: 35%;
    }
  }
`;

export const StyledConsultation = styled.div`
  position: relative;
  background: url(${bgImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 3em;

  h3 {
    text-transform: capitalize;
    font-size: 2em;
    color: var(--white);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--gray-5-alpha);
  }

  > div {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    z-index: 1;
    height: 28em;

    .phone-and-link-section {
      .get-consult-btn {
        border: 0.1em solid var(--white);
        padding: 1em;
        color: var(--white);
        text-transform: capitalize;
        font-weight: 500;
        text-decoration: none;
      }
    }
  }
`;

export const StyledPhone = styled.div`
  margin-bottom: 2em;

  p {
    color: var(--white);
    margin-bottom: 0.5em;
    font-size: 1.2em;
  }

  ul {
    display: column;
    list-style: none;
    gap: 1em;

    li {
      a {
        color: var(--white);
        font-size: 1.2em;
      }
    }
  }
`;
