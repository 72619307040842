import { Link } from "react-router-dom";
import Container from "../Container/Container";
import { FaXTwitter, FaFacebook, FaLinkedin } from "react-icons/fa6";

import "./styles.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-section">
      <Container>
        <div className="footer-section-top">
          <div className="footer-address-section">
            <ul>
              <li>
                <span>address:</span>
                <span>
                  ADESHINA HOUSE, No 3 Jimoh Close, Off Plaza Hotel Road, Sango
                  Ota, Ogun State, Nigeria.
                </span>
              </li>
              <li>
                <span>phone:</span>
                <span>+2348030760589, +2348149499035</span>
              </li>
              <li>
                <span>email:</span>
                <span>
                  info@bandelengineering.com, dele@bandelengineering.com
                </span>
              </li>
            </ul>
          </div>
          <div className="footer-newsletter-section">
            <div className="newsletter-form">
              <h4>newsletter:</h4>
              <form>
                <input type="email" placeholder="Your email here" />
                <button type="submit">subscribe</button>
              </form>
            </div>
            <div className="social-platforms">
              <h4>social:</h4>
              <ul className="social-items">
                <li className="social-item">
                  <Link to="#">
                    <FaFacebook />
                  </Link>
                </li>
                <li className="social-item">
                  <Link to="#">
                    <FaLinkedin />
                  </Link>
                </li>
                <li className="social-item">
                  <Link to="#">
                    <FaXTwitter />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
      <div className="footer-section-bottom">
        <Container>
          <p>
            bandel engineering ltd. &copy;
            {currentYear}. all rights reserved
          </p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
