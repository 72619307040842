import styled from "styled-components";

export const StyledWrapper = styled.div`
  padding: 3em 0;
  background: var(--dark-blue-2);

  > h2 {
    margin-left: 15%;
    margin-bottom: 1em;
    color: var(--white);
    text-transform: capitalize;
    font-size: 2.2em;
  }

  @media screen and (min-width: 991px) {
    > h2 {
      margin-bottom: 2em;
    }
  }
`;

export const StyledClientsCarousel = styled.div``;

export const StyledClientLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5em;
  background: var(--white);

  img {
    width: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: 991px) {
    height: 10em;

    img {
      width: 80%;
    }
  }
`;
