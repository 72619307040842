import styled from "styled-components";

export const StyledWrapper = styled.div`
  position: relative;
  background: var(--dark-blue-2);
  padding: 5em 0;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: 100%;
  margin: 0 auto;
  padding: 3em;
  border-top-left-radius: 5em;
  gap: 1em;

  @media screen and (min-width: 991px) {
    width: 70%;
    flex-direction: row;
    border-top-left-radius: 0;
  }
`;

export const StyledContentLeft = styled.div`
  width: 100%;
  order: 2;

  @media screen and (min-width: 991px) {
    order: 1;
    width: 50%;
  }
`;

export const StyledContentRight = styled.div`
  position: relative;
  width: 100%;
  order: 1;

  p {
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    margin-top: 1em;
  }

  @media screen and (min-width: 991px) {
    width: 50%;
    order: 2;

    p {
      font-size: 1.2em;
    }
  }
`;

export const StyledImg = styled.img`
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 300px;
  object-fit: contain;

  @media screen and (max-width: 990.98px) {
    bottom: 0;
    left: 0;
  }

  @media screen and (min-width: 991px) {
    width: 500px;
    top: 0;
    left: 0;
  }
`;

export const StyledLogo = styled.div`
  @media screen and (max-width: 990.98px) {
    width: 70%;
    margin: 0 auto;

    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
`;

export const StyledHeading = styled.div`
  position: absolute;
  display: inline-block;
  background: var(--red-1);
  width: 80%;
  top: -40%;
  left: 50%;
  transform: translateX(-50%);

  h3 {
    color: var(--white);
    text-transform: capitalize;
    padding: 1em;
    text-align: center;
  }

  @media screen and (min-width: 991px) {
    width: 55%;
    top: -30%;
    left: 0%;
    transform: translateX(0);
  }
`;
