/* eslint-disable no-undef */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  StyledWrapper,
  StyledSectionLeft,
  StyledSectionRight,
  StyledSection,
  StyledFormSection,
  StyledConsultation,
  StyledPhone,
} from "./styles";

const ContactUs = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <StyledWrapper id="contact-us">
      <StyledSection>
        <StyledSectionLeft>
          <StyledFormSection>
            <h2>what can we do for you?</h2>
            <p>
              We are ready to work on a project of any complexity, whether
              it&apos;s commercial or residential.
            </p>
            <form>
              <input type="text" placeholder="your name*" />
              <input type="email" placeholder="your email*" />
              <input type="text" name="" placeholder="your phone*" />
              <textarea name="" placeholder="your message" rows={10}></textarea>
              <small>* indicates a required field</small>
              <button>submit</button>
            </form>
          </StyledFormSection>
        </StyledSectionLeft>
        <StyledSectionRight>
          <StyledConsultation>
            <div>
              <h3>Free consultation with exceptional quality</h3>
              <div className="phone-and-link-section">
                <StyledPhone>
                  <p>Just one call away:</p>
                  <ul>
                    <li>
                      <Link to="tel:+2348030760589">+2348030760589</Link>
                    </li>
                    <li>
                      <Link to="tel:+2348149499035">+2348149499035</Link>
                    </li>
                  </ul>
                </StyledPhone>
                <Link to="#" className="get-consult-btn">
                  get your consultation
                </Link>
              </div>
            </div>
          </StyledConsultation>
        </StyledSectionRight>
      </StyledSection>
    </StyledWrapper>
  );
};

export default ContactUs;
