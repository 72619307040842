interface NavItem {
  title: string;
  link: string;
}

export const navData: NavItem[] = [
  {
    title: "Home",
    link: "/#hero-section",
  },
  {
    title: "About",
    link: "/#about-us",
  },
  {
    title: "Services",
    link: "/#services",
  },
];
