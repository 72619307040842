import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  /* VARS */
  :root{
    --white: #fff;
    --black: #000;
    --dark-blue-1: #292e3d;
    --light-blue-1: #858ead;
    --blue-1: #2947a9;
    --gray-1: #e0e3eb;
    --gray-2: #c2c7d6;
    --gray-3: #f6f8f7;
    --gray-4: #a3aac2;
    --gray-5-alpha: rgba(45, 45, 45,0.5);
    --red-1: #ff0000;
    --orange-1: #f9995d;
    --dark-blue-1: #14171F;
    --dark-blue-2: #172139;
  }

  /* RESETS */
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
  }
`;
