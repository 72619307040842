import Container from "../shared/Container/Container";
import {
  StyledWrapper,
  StyledContent,
  StyledContentLeft,
  StyledContentRight,
} from "./styles";

import img from "../../assets/img/definition-img.jpg";

const WhatDefinesUs = () => {
  return (
    <StyledWrapper>
      <Container width="70%">
        <StyledContent>
          <StyledContentLeft>
            <h3>what defines us</h3>
            <p>
              Our dedication to excellence, innovation, and sustainability
              distinguishes us. With almost ten years of experience, we take
              pride in consistently delivering engineering solutions of the
              highest quality that surpass client expectations. Our expert team
              utilizes advanced technologies and a client-focused approach to
              guarantee the success of every project. Integrity, precision, and
              continuous improvement form the foundation of all our efforts.
            </p>
          </StyledContentLeft>
          <StyledContentRight>
            <img src={img} alt="bandel engineering" />
          </StyledContentRight>
        </StyledContent>
      </Container>
    </StyledWrapper>
  );
};

export default WhatDefinesUs;
