import styled from "styled-components";

export const StyledWrapper = styled.div`
  padding: 5em 0;
  background: var(--dark-blue-2);
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  @media screen and (min-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledContentLeft = styled.div`
  width: 100%;

  h3 {
    font-size: 2em;
    color: var(--white);
    text-transform: capitalize;
    margin-bottom: 1em;
  }

  p {
    font-size: 1.1em;
    width: 100%;
    line-height: 1.5;
    color: var(--white);
  }

  @media screen and (min-width: 991px) {
    width: 50%;

    p {
      width: 80%;
    }
  }
`;

export const StyledContentRight = styled.div`
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 991px) {
    width: 50%;
  }
`;
