import img1 from "../assets/img/service-img-1.jpg";
import img2 from "../assets/img/service-img-2.jpg";
import img3 from "../assets/img/service-img-3.jpg";

interface ServiceItem {
  img: string;
  title: string;
}

export const serviceData: ServiceItem[] = [
  {
    img: img1,
    title: "civil works",
  },
  {
    img: img2,
    title: "electrical & automation",
  },
  {
    img: img3,
    title: "mechanical services",
  },
];
