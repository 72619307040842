import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { clientData } from "../../data/clientData";
import {
  StyledWrapper,
  StyledClientsCarousel,
  StyledClientLogo,
} from "./styles";

const OurClients = () => {
  return (
    <StyledWrapper>
      <h2>our clients</h2>
      <StyledClientsCarousel>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={5}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 5,
              // spaceBetween: 20,
            },
            768: {
              slidesPerView: 6,
              //spaceBetween: 40,
            },
            1024: {
              slidesPerView: 8,
              spaceBetween: 30,
            },
          }}
        >
          {clientData.map((client, index) => (
            <SwiperSlide key={index}>
              <StyledClientLogo>
                <img src={client.img} alt="sample" />
              </StyledClientLogo>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledClientsCarousel>
    </StyledWrapper>
  );
};

export default OurClients;
