import styled from "styled-components";

export const StyledWrapper = styled.div`
  padding: 5em 0;
`;

export const StyledContent = styled.div`
  h2 {
    text-transform: capitalize;
    font-size: 2.5em;
  }

  p {
    line-height: 1.5;
    font-size: 1em;
    margin: 1em 0;
  }
`;

export const StyledServiceItems = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1em;
  margin-top: 2em;
`;

export const StyledServiceItem = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 20em;
  padding: 1em;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  h3 {
    position: relative;
    z-index: 2;
    color: var(--white);
    text-transform: capitalize;
    font-size: 1.2em;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(147, 147, 147, 0) 0%,
      #ff0000 100%
    );
    z-index: 1;
  }
`;
