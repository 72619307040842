/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Container from "../Container/Container";
import { navData } from "../../../data/navData";
import { RxHamburgerMenu } from "react-icons/rx";
import { RiCloseLargeLine } from "react-icons/ri";

import {
  StyledWrapper,
  StyledNavbar,
  StyledNavbarLeft,
  StyledNavbarRight,
  StyledLogo,
  StyledNavItems,
  StyledNavItem,
  StyledHamburger,
} from "./styles";

import logo from "../../../assets/img/logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <StyledWrapper>
      <Container>
        <StyledNavbar>
          <StyledNavbarLeft>
            <StyledLogo>
              <img src={logo} alt="Bandel Engineering" />
            </StyledLogo>
          </StyledNavbarLeft>
          <StyledNavbarRight $isOpen={isOpen}>
            <StyledNavItems>
              {navData.map((item) => (
                <StyledNavItem key={item.title}>
                  <Link to={item.link} onClick={() => setIsOpen(false)}>
                    {item.title}
                  </Link>
                </StyledNavItem>
              ))}
              <StyledNavItem>
                <Link to="/#contact-us" onClick={() => setIsOpen(false)}>
                  contact
                </Link>
              </StyledNavItem>
            </StyledNavItems>
          </StyledNavbarRight>
          <StyledHamburger>
            {isOpen ? (
              <span>
                <RiCloseLargeLine onClick={() => setIsOpen(false)} />
              </span>
            ) : (
              <span>
                <RxHamburgerMenu onClick={() => setIsOpen(true)} />
              </span>
            )}
          </StyledHamburger>
        </StyledNavbar>
      </Container>
    </StyledWrapper>
  );
};

export default Navbar;
