import Hero from "../../components/Hero/Hero";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import MissionAndVision from "../../components/MissionAndVision/MissionAndVision";
import Services from "../../components/Services/Services";
import OurProjects from "../../components/OurProjects/OurProjects";
import WhatDefinesUs from "../../components/WhatDefinesUs/WhatDefinesUs";
import OurClients from "../../components/OurClients/OurClients";
import ContactUs from "../../components/ContactUs/ContactUs";
const Home = () => {
  return (
    <>
      <Hero />
      <WhoWeAre />
      <MissionAndVision />
      <Services />
      <OurProjects />
      <WhatDefinesUs />
      <OurClients />
      <ContactUs />
    </>
  );
};

export default Home;
