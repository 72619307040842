import {
  StyledWrapper,
  StyledContent,
  StyledContentItem,
  StyledContentItemLeft,
  StyledContentItemRight,
} from "./styles";

import visionImg from "../../assets/img/vision-icon.png";
import missionImg from "../../assets/img/mission-icon.png";

const MissionAndVision = () => {
  return (
    <StyledWrapper>
      <StyledContent>
        <StyledContentItem>
          <StyledContentItemLeft>
            <h3>vision</h3>
            <img src={visionImg} alt="" />
          </StyledContentItemLeft>
          <StyledContentItemRight>
            <p>
              To be the firm of choice in the engineering and construction
              services.
            </p>
          </StyledContentItemRight>
        </StyledContentItem>
        <StyledContentItem>
          <StyledContentItemLeft>
            <h3>mission</h3>
            <img src={missionImg} alt="" />
          </StyledContentItemLeft>
          <StyledContentItemRight>
            <p>
              To be a hallmark of excellence in project execution and service
              delivery.
            </p>
          </StyledContentItemRight>
        </StyledContentItem>
      </StyledContent>
    </StyledWrapper>
  );
};

export default MissionAndVision;
