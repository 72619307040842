import img1 from "../assets/img/projects/proj-1.jpeg";
import img2 from "../assets/img/projects/proj-2.jpeg";
import img3 from "../assets/img/projects/proj-3.jpeg";
import img4 from "../assets/img/projects/proj-4.jpeg";

export interface ProjectItem {
  img: string;
  title: string;
  description: string;
}

export const projects = [
  {
    img: img1,
    title: "The Nigeria Labour Shopping Complex",
    description:
      "The Nigeria Labour Shopping Complex in Sango-Ota, covering 500 square meters, was efficiently managed by Bandel Engineering Limited, ensuring high quality and safety standards, and timely completion.",
  },
  {
    img: img2,
    title: "Gas Plant for Mikel Energy in Ijebu Ode, Ogun State, Nigeria",
    description:
      "The gas plant for Mikel Energy in Ijebu Ode spans 600 square meters and features state-of-the-art technology for efficient and safe gas processing. Despite the complexities, Bandel Engineering Limited executed the project with precision, adhering to a strict timeline through meticulous planning and resource allocation.",
  },
  {
    img: img3,
    title: "Semi-detached and fully detached Apartment at Lekki, Lagos.",
    description:
      "The Lekki County Homes project in Ikota, Lekki, features luxurious semi-detached and fully detached apartments with modern architecture and high-end finishes. Bandel Engineering managed the complex development, including infrastructure like roads and utilities, using rigorous project management to ensure timely completion of each phase.",
  },
  {
    img: img4,
    title: "Residential Apartment at Covenant University, Ota, Ogun State.",
    description:
      "The residential apartments project at Covenant University, Ota, faced challenges like difficult topography and fluctuating material costs. Despite this, Bandel Engineering adhered to a structured timeline, completing foundation, structural, and finishing works as scheduled.",
  },
];
