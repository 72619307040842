import styled from "styled-components";

export const StyledWrapper = styled.div`
  background: var(--dark-blue-2);
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 90%;
  margin: 0 auto;
  padding: 3em 0;

  @media screen and (min-width: 991px) {
    width: 50%;
  }
`;

export const StyledContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  &:nth-child(2) {
    > div:nth-child(1) {
      img {
        width: 30%;
      }
    }
  }

  @media screen and (max-width: 990.98px) {
    align-items: center;
  }

  @media screen and (min-width: 991px) {
    flex-direction: row;

    &:nth-child(2) {
      > div:nth-child(1) {
        img {
          width: 25%;
        }
      }
    }
  }
`;

export const StyledContentItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  h3 {
    font-size: 1.5em;
    color: var(--white);
    text-transform: capitalize;
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 0.5em;
  }

  img {
    width: 40%;
    object-fit: contain;
  }

  @media screen and (min-width: 991px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 40%;

    h3 {
      margin-bottom: 0;
    }

    img {
      width: 30%;
    }
  }
`;

export const StyledContentItemRight = styled.div`
  width: 80%;
  background: var(--red-1);

  p {
    color: var(--white);
    padding: 1em;
  }

  @media screen and (min-width: 991px) {
    width: 60%;
  }
`;
